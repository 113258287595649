import { useStaticQuery, graphql } from "gatsby"

export const useCareerTalkingPoints = () => {
  const { allSanityTalkingPoint } = useStaticQuery(
    graphql`
      query CareersTalkingPointsQuery {
        allSanityTalkingPoint {
          edges {
            node {
              id
              order
              title
              description
              color
              truncate
            }
          }
        }
      }
    `
  )

  return allSanityTalkingPoint.edges.sort((a, b) => a.node.order - b.node.order)
}