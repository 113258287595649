import React, { useState, useEffect, Fragment } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

import Markdown from 'markdown-to-jsx';
import MarkdownAlt from 'react-markdown';

import { useCareerTalkingPoints } from '../hooks/useCareerTalkingPoints';

import {
  ARC_NET_URL,
  CAREERS_EMAIL,
  CAREERS_EMAIL_TEXT_ONLY,
  TWITTER_URL,
  WAITLIST_FORM_URL,
} from '../components/Constants';
import { ToggleItem } from '../components/ToggleItem';
import Helmet from 'react-helmet';
import SEO from '../components/seo';
import { getSlugFromString, getAccessibleColor } from '../components/utils';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import OGImage from '../images/og.png';
import IconArrowDown from '../images/icon-arrow-down.svg';
import IconArrowDownRight from '../images/icon-arrow-down-right.svg';
import IconArrowUp from '../images/icon-arrow-up.svg';
import IconArrowUpWhite from '../images/icon-arrow-up-white.svg';
import LogoBlack from '../images/logo-bcny-black.svg';
import LogoFooter from '../images/logo-bcny-tall.svg';
import ChatBubbleGreenImage from '../images/chat-bubble-green.svg';
import ChatBubbleBlueImage from '../images/chat-bubble-blue.svg';
import Squiggle from '../images/squiggle.svg';

import { notSmall, medium, large } from '../styles/mediaQueryMixins';

const BodyStyle = createGlobalStyle`
  body {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
  .slide-pane__overlay {
    z-index: 9999;
  }
`;

const Sticky = styled.section`
  ${notSmall`
    position: sticky;
    top: 0;
  `};
`;

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  font-size: 16px;

  ${notSmall`
    font-size: 22px;
  `};

  ${medium`
    font-size: 26px;
  `};

  ${large`
    font-size: 28px;
  `};
`;

const Container = styled.section`
  width: 100%;
  max-width: ${(props) => props.maxWidth ?? 'none'};
`;

const DecorativeNumber = styled.span`
  font-family: 'Space Mono';
  font-size: 0.75em;
  font-weight: 300;
  padding-right: 4px;
  padding-left: 4px;

  color: ${(props) => props.tintColor ?? '#000'};

  ${notSmall`
    padding-right: 10px;
    padding-left: 4px;
  `};
`;

const DecorativeNumberLarge = styled.span`
  font-family: 'Space Mono';
  font-size: 1em;
  font-weight: 300;
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 10px;
  display: block;
  color: ${(props) => props.tintColor ?? '#000'};
`;

const ColorfulButton = styled.button`
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 30px;
  bottom: 2px;

  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 10px;

  color: #000;
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 0.75em;
  text-transform: uppercase;
  line-height: 16px;

  border-radius: 20px;
  border: 3px solid #000;
  background-color: ${(props) => props.tintColor ?? '#FFF'};

  ${notSmall`
    height: 36px;
    bottom: 5px;

    padding-left: 16px;
    padding-right: 16px;
    margin-left: 10px;
    margin-right: 20px;

    font-size: 0.5em;
    line-height: 28px;

    border: 4px solid #000;
    transition: .1s box-shadow;

    &:hover {
      box-shadow: -3px -3px 0 #000;
    }
  `};
`;

const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

/**
 * Back to Main
 */
const BackToMainLinkContainer = styled.nav`
  text-align: center;
  background-color: #0047ff;

  ${notSmall`
    text-align: left;
    align-self: flex-start;
    background: none;
    padding-left: 20px;
    padding-right: 20px;
 `};
`;

const BackToMainLink = styled((props) => <Link {...props} />)`
  display: inline-block;
  height: 60px;
  padding-left: 24px;

  line-height: 60px;
  color: #fff;
  font-family: 'Space Mono';
  font-weight: 300;
  font-size: 1em;

  background-image: url(${IconArrowUpWhite});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-y: 50%;

  ${notSmall`
    margin-top: 20px;
    font-size: 0.5em;
    color: #0047FF;
    background-image: url(${IconArrowUp});

    transition: .1s background-position-y;

    &:hover {
      background-position-y: 45%;
    }
  `};
`;

const BackToMainLinkLockUp = () => (
  <BackToMainLinkContainer>
    <BackToMainLink to="/">Back to Main Site</BackToMainLink>
  </BackToMainLinkContainer>
);

/**
 * Header
 */
const HeaderContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 10px;
  padding-top: 24px;
`;
const BCNYLogo = styled.span`
  width: 65px;
  height: 70px;
  background-image: url(${LogoBlack});
  background-repeat: no-repeat;
  background-position: 50%;
`;

const ChatBubbleGreen = styled.span`
  position: absolute;
  height: 90px;
  width: 200px;

  top: 140px;
  margin-left: 34px;

  text-align: center;
  text-transform: uppercase;
  font-size: 1em;
  line-height: 120px;
  font-weight: bold;

  background-image: url(${ChatBubbleGreenImage});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 200px;

  ${notSmall`
    top: 160px;
    font-size: 0.75em;
    background-size: 220px;
    height: 96px;
    width: 220px;
    line-height: 128px;
  `}
`;

const JoinWaitlistButton = styled.a`
  border: none;
  background: none;

  color: #000;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 900;

  ${notSmall`
    text-decoration: none;
    font-size: 0.75em;
    background-color: #F4F3E4;
    border-radius: 30px;
    padding-top: 15px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 30px;
    border: 4px solid #000;

    transition: .1s box-shadow;

    &:hover {
      box-shadow: -6px -6px 0 #000;
    }
  `};
`;

const HeaderLockup = () => (
  <HeaderContainer>
    <BCNYLogo>
      <ScreenReaderOnly>The Browser Company</ScreenReaderOnly>
    </BCNYLogo>

    <ChatBubbleGreen>Careers Page</ChatBubbleGreen>

    <JoinWaitlistButton href={ARC_NET_URL}>Get Arc</JoinWaitlistButton>
  </HeaderContainer>
);

/**
 * Page Title
 */
const PageTitleContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 30px;

  ${notSmall`
    padding-top: 170px;
    padding-bottom: 120px;
  `};
`;

const PageTitle = styled.h3`
  margin: 0 0 5px 0;
  padding-bottom: 60px;

  font-size: 2.5em;
  font-weight: 600;
  text-transform: uppercase;

  background-image: url(${IconArrowDown});
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;

  ${notSmall`
    font-size: 1.75em;
    padding-bottom: 70px;
  `};
`;

const PageTitleLockup = () => (
  <PageTitleContainer>
    <PageTitle>Hello</PageTitle>
  </PageTitleContainer>
);

/**
 * Talking Points
 */
const PointTitle = styled.h2`
  display: inline;
  text-transform: uppercase;
  font-size: 1.25em;
  letter-spacing: -0.02em;
  margin-right: 10px;
`;

const PointExcerpt = styled.span`
  font-family: 'Source Serif Pro';
  font-size: 1.2em;
  font-weight: 300;
  opacity: 1;

  ${notSmall`
   opacity: 0.45;
 `};
`;

const TalkingPointsInlineContainer = styled.section`
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 2em;

  ${notSmall`
    text-align: center;
    line-height: 2em;
    padding-left: 24px;
    padding-right: 24px;
  `}
`;

const TalkingPointSingleContainer = styled.section`
  padding-bottom: 30px;

  ${({ hasMore }) =>
    hasMore &&
    `
    cursor: pointer;
  `};

  & p {
    display: inline;
  }

  ${notSmall`
    ${({ hasMore }) =>
      hasMore &&
      `
      padding-bottom: 12px;
      padding-top: 12px;
      margin-bottom: 30px;
      &:hover {
        background-color: #F2F6FF;
        box-shadow: 3px 0 0 #F2F6FF, -6px 0 0 #F2F6FF; // Fake padding on either side of the line
      };
    `};
  `};
`;

const TalkingPointInlineWithMoreButton = ({
  number,
  title,
  subtitle,
  color,
  navigateToTalkingPoint,
}) => {
  const accessibleColor = getAccessibleColor(color, '#fff');
  return (
    <TalkingPointSingleContainer
      color={color}
      hasMore={navigateToTalkingPoint != undefined}
      onClick={() => {
        navigateToTalkingPoint(getSlugFromString(title));
      }}
    >
      <DecorativeNumber tintColor={accessibleColor}>{number}</DecorativeNumber>
      <PointTitle>{title}</PointTitle>
      <PointExcerpt>{subtitle} </PointExcerpt>
      {navigateToTalkingPoint && (
        <ColorfulButton
          tintColor={color}
          onClick={() => {
            navigateToTalkingPoint(getSlugFromString(title));
          }}
        >
          More
        </ColorfulButton>
      )}
    </TalkingPointSingleContainer>
  );
};

const TalkingPointInline = ({ number, title, body, color }) => {
  const accessibleColor = getAccessibleColor(color, '#fff');
  return (
    <TalkingPointSingleContainer>
      <DecorativeNumber tintColor={accessibleColor}>{number}</DecorativeNumber>
      <PointTitle>{title}</PointTitle>
      <PointExcerpt>
        <Markdown
          options={{
            overrides: {
              ToggleItem: {
                component: ToggleItem,
              },
              Markdown: {
                component: MarkdownAlt,
              },
            },
          }}
        >
          {body}
        </Markdown>
      </PointExcerpt>
    </TalkingPointSingleContainer>
  );
};

const PointTitleLarge = styled(PointTitle)`
  display: block;
  font-size: 1.2em;
  margin: 0;
  padding: 0;

  ${notSmall`
   font-size: 2.25em;
   line-height: 1.5em;
 `};

  ${medium`
    font-size: 1.2em;
    line-height: 1.5em;
  `}
`;

const TalkingPointFullContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: top;

  ${notSmall`
    flex-direction: row;
    padding-top: 150px;
  `}
`;
const TalkingPointHeaderLockup = styled.hgroup`
  scroll-margin-top: 20px;

  ${notSmall`
    scroll-margin-top: 100px;
    flex: 400px 0;
    padding-left: 20px;
    padding-right: 60px;
    top: 0;
  `};

  ${medium`
    flex: 200px 0;
  `}
`;

const PointBody = styled.section`
  font-family: 'Source Serif Pro';
  font-size: 1.1em;
  line-height: 1.5em;
  font-weight: 300;
  margin-top: 11px; // align the baseline to the title

  flex: 1;

  & ul {
    margin-left: 16px;
    padding: 0;
  }
  & ul li {
    margin-bottom: 18px;
  }
  & strong {
    font-weight: 600;
  }
  & a,
  & a:visited {
    color: #0047ff;
  }

  ${notSmall`
    font-size: 1.75em;
    line-height: 1.75em;
    & ul {
      margin-left: 26px;
      padding: 0;
    }
  `};

  ${medium`
    font-size: 1.2em;
  `}
`;

const TalkingPointFull = ({
  navigateToTalkingPoint,
  number,
  title,
  body,
  color,
}) => (
  <TalkingPointFullContainer key={number}>
    <TalkingPointHeaderLockup id={getSlugFromString(title)}>
      <Sticky>
        <DecorativeNumberLarge tintColor={color}>
          {number}
        </DecorativeNumberLarge>
        <PointTitleLarge>{title}</PointTitleLarge>
      </Sticky>
    </TalkingPointHeaderLockup>
    <PointBody>
      <Markdown
        options={{
          overrides: {
            ToggleItem: {
              component: ToggleItem,
            },
            Markdown: {
              component: MarkdownAlt,
            },
          },
        }}
      >
        {body}
      </Markdown>
    </PointBody>
  </TalkingPointFullContainer>
);

const StyledSlidingPane = styled((props) => <SlidingPane {...props} />)`
  max-width: 1400px;
`;

const TalkingPointsPanel = ({
  isOpen,
  navigateToTalkingPoint,
  points,
}) => (
  <StyledSlidingPane
    isOpen={isOpen}
    hideHeader={true}
    width={'95vw'}
    onRequestClose={() => navigateToTalkingPoint('')}
  >
    {points.map((data) => {
      const title = data.node.title;
      const pointNumber = String(data.node.order).padStart(2, '0');
      const color = data.node.color;
      const body = data.node.description;

      return (
        <TalkingPointFull
          key={data.node.id}
          navigateToTalkingPoint={navigateToTalkingPoint}
          number={pointNumber}
          title={title}
          body={body}
          color={color}
        />
      );
    })}
    <TalkingPointFullContainer>
      <TalkingPointHeaderLockup>
        <Sticky>
          <PointTitleLarge>Open Positions</PointTitleLarge>
        </Sticky>
      </TalkingPointHeaderLockup>
      <PointBody>
        <OpenPositionsLockup />
      </PointBody>
    </TalkingPointFullContainer>
  </StyledSlidingPane>
);

const TalkingPointsCloud = ({ navigateToTalkingPoint, points }) => (
  <TalkingPointsInlineContainer>
    {points.map((data) => {
      let showMoreButton = data.node.truncate;
      let pointNumber = String(data.node.order).padStart(2, '0');
      let words = data.node.description.split(' ');
      let excerpt = '';
      for (let i = 0; i < words.length; i++) {
        if ((excerpt + words[i]).length <= 64) {
          excerpt += ' ' + words[i];
        } else {
          break;
        }
      }
      excerpt += '...';
      return (
        <Fragment key={data.node.id}>
          {showMoreButton ? (
            <TalkingPointInlineWithMoreButton
              number={pointNumber}
              title={data.node.title}
              subtitle={excerpt}
              color={data.node.color}
              navigateToTalkingPoint={navigateToTalkingPoint}
            />
          ) : (
            <TalkingPointInline
              number={pointNumber}
              title={data.node.title}
              body={data.node.description}
              color={data.node.color}
            />
          )}
        </Fragment>
      );
    })}
  </TalkingPointsInlineContainer>
);

/**
 * Jobs List
 */
const OpenPositionsLockup = () => (
  <OpenPositionsContainer>
    <li>
      <a href="https://jobs.ashbyhq.com/The%20Browser%20Company">Open Roles</a>
    </li>
  </OpenPositionsContainer>
);

const OpenPositionsContainer = styled.section`
  font-family: 'Space Mono';
  font-size: 1.25em;

  & a,
  & a:visited {
    color: #0047ff;
  }

  & ul {
    margin-top: 20px;
    margin-left: 0;
    padding: 0;
  }

  & li {
    margin-top: 20px;
    padding-left: 40px;
    list-style: none;
    background-image: url(${IconArrowDownRight});
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: 0;
  }

  ${notSmall`
    & ul {
      margin-top: 60px;
    }
    & li {
      margin-top: 30px;
      padding-left: 60px;
      background-size: 40px;
      background-position-y: 30%;
      transition: .1s background-position-x;
    }

    & li:hover {
      background-position-x: 10px;
    }
  `}
`;

// Lets jump the footer to columns rather
// than rows a bit earlier than the other
// breakpoints.
const footerBreakpoint = (...args) => css`
  @media screen and (min-width: 50em) {
    ${css(...args)}
  }
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;

  ${footerBreakpoint`
    flex-direction: row;
    align-items: top;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
  `};
`;

const FooterBackgroundColor = styled.div`
  margin-top: -70px; // pull it under the chat bubble
  padding-top: 80px;
  background-color: #f4f3e4;
  width: 100%;
`;

const FooterLogoContainer = styled.section`
  padding-top: 40px;
  box-sizing: border-box;

  ${footerBreakpoint`
    order: 1;
    display: block;
    min-height: 330px;

    padding-right: 80px;
    padding-top: 110px;

    background-image: url(${Squiggle});
    background-repeat: repeat-y;
    background-position-x: 100%;

    text-align: center;
  `}
`;

const FooterContactUsContainer = styled.section`
  font-family: 'Source Serif Pro';
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.5em;

  & ul {
    margin: 0;
    padding-left: 0;
    padding-top: 20px;
  }

  & li {
    list-style: none;
    margin-bottom: 10px;
    font-family: 'Nunito';
    font-size: 0.9em;
  }

  & a {
    font-family: 'Space Mono';
    font-size: 0.95em;
  }

  ${footerBreakpoint`
    font-size: .95em;
    padding-left: 100px;
    order: 2;
  `}
`;

const ChatBubbleBlue = styled.span`
  z-index: 1;
  box-sizing: border-box;
  display: block;
  height: 150px;
  width: 220px;

  padding-top: 28px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 90px;
  margin-left: auto;
  margin-right: auto;

  // fix the fontsize because we don't
  // want the chat bubble text changing
  // as the base font-size of the page changes
  font-size: 28px;
  font-family: 'Nunito';
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25em;
  font-weight: bold;

  background-image: url(${ChatBubbleBlueImage});
  background-repeat: no-repeat;
  background-size: 200px;
  background-position-x: 0;
  background-size: 220px 146px;

  ${footerBreakpoint`
    margin-top: 140px;
    position: relative;
    left: -250px;
  `}
`;

const YourFriendsAt = styled.span`
  display: block;
  font-family: 'Space Mono';
  font-size: 0.65em;
  text-transform: uppercase;
  text-align: center;

  padding-bottom: 10px;

  ${footerBreakpoint`
    padding-top: 0;
    font-size: 0.5em;
  `}
`;

const BCNYLogoFooter = styled.span`
  display: block;
  margin: 0 auto;
  width: 150px;
  height: 76px;
  background-image: url(${LogoFooter});
  background-repeat: no-repeat;
  background-position: 50%;

  ${footerBreakpoint`
    width: 260px;
    height: 100px;
  `}
`;

const FooterLockup = () => (
  <>
    <ChatBubbleBlue>Let’s keep in touch!</ChatBubbleBlue>
    <FooterBackgroundColor>
      <FooterContainer>
        <FooterContactUsContainer>
          <p>
            Just like any other careers page or job description, this page is
            probably not perfect — so please apply or reach out with questions
            if you’re interested!
          </p>
          <ul>
            <li>
              <strong>Questions?</strong>{' '}
              <a href={`${CAREERS_EMAIL}`}>{`${CAREERS_EMAIL_TEXT_ONLY}`}</a>
            </li>
          </ul>
        </FooterContactUsContainer>
        <FooterLogoContainer>
          <YourFriendsAt>Your friends at</YourFriendsAt>
          <BCNYLogoFooter>
            <ScreenReaderOnly>The Browser Company</ScreenReaderOnly>
          </BCNYLogoFooter>
        </FooterLogoContainer>
      </FooterContainer>
    </FooterBackgroundColor>
  </>
);

const JobsPage = ({ location }) => {
  const talkingPoints = useCareerTalkingPoints();
  const [activeSlug, setActiveSlug] = useState(location.hash);

  // If we have an active slug, stop the
  // body from being able to scroll
  useEffect(() => {
    if (activeSlug !== '') {
      let slugToNavigateTo = activeSlug;
      // If you reload the page, location.hash includes the #,
      // whereas if you navigate to it, it does not. This normalizes
      // the string so we only prefix a # if needed
      if (slugToNavigateTo.charAt(0) !== '#') {
        slugToNavigateTo = `#${slugToNavigateTo}`;
      }
      navigate(slugToNavigateTo, { replace: true });
      document.body.style.overflow = 'hidden';
    } else {
      navigate(' ', { replace: true });
      document.body.style.overflow = 'unset';
    }
  }, [activeSlug]);

  const navigateToTalkingPoint = (slug) => {
    setActiveSlug(slug ?? '');
  };

  return (
    <Layout>
      <SEO title="Careers | The Browser Company" />
      <Helmet>
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="643" />
        <html className="dark" />
      </Helmet>
      <BodyStyle />
      <TalkingPointsPanel
        isOpen={activeSlug !== ''}
        navigateToTalkingPoint={(slug) => navigateToTalkingPoint(slug)}
        points={talkingPoints}
      />
      <Container maxWidth="1200px">
        <BackToMainLinkLockUp />
        <HeaderLockup />
        <PageTitleLockup />
      </Container>
      <Container maxWidth="1024px">
        <TalkingPointsCloud
          navigateToTalkingPoint={(slug) => navigateToTalkingPoint(slug)}
          points={talkingPoints}
        />
      </Container>
      <OpenPositionsLockup />
      <FooterLockup />
    </Layout>
  );
};

export default JobsPage;
